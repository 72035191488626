import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const Aside = ({ setFomValueReason }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* <h3 className="aside__title--title">
        <Trans>page_contact_aside_title</Trans>
      </h3>

      <div className="form-group select select-white">
        <select
          name="reason"
          id="reason"
          className="form-control"
          onChange={(e) => {
            setFomValueReason(e.target.value)
          }}
        >
          <option value="problem">{t('page_contact_aside_problem')}</option>
          <option value="question">{t('page_contact_aside_question')}</option>
        </select>
      </div> */}
    </>
  )
}

export default Aside
