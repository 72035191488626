import React, { useState } from 'react'
import { LangSelect, NavContent } from './constants'
import { Logo } from '../Logo'
import Close from '../../../static/img/close.svg'
import Menu from '../../../static/img/with-banner/burger-menu.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StickyHeader = ({ children, asideContent }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const { t } = useTranslation()
  const toggleMenuOpen = () => {
    setIsOpen(!isOpen)
  }

  const toggleMobileMenuOpen = () => {
    setIsOpenMobile(!isOpenMobile)
  }
  return (
    <div className="container-fluid px-0 py-0">
      <div className="row main-content">
        <div className="col-lg-5 col-md-6 bg-white aside">
          <div className="top-bar container-fluid full-padding">
            <div className="d-flex align-items-center">
              <div className="top-bar__logo mr-auto">
                <Logo black={true} />
              </div>

              <div className="top-bar__menu nav">
                <img
                  className="open-nav burger--responsive"
                  src="/img/menu-responsive.svg"
                  alt=""
                  onClick={toggleMobileMenuOpen}
                />

                <div
                  className="nav-menu"
                  style={{ display: isOpenMobile ? 'block' : 'none' }}
                >
                  <div className="close">
                    <img
                      src={'/img/close.svg'}
                      alt=""
                      onClick={toggleMobileMenuOpen}
                    />
                  </div>
                  <NavContent />
                </div>
              </div>
            </div>
          </div>

          <div className="p-absolute aside__title">
            <div className="container-fluid full-padding">{asideContent}</div>
          </div>
        </div>

        <div className="col-lg-7 col-md-6 bg-grey content">
          <div className="container-fluid full-padding">
            <div className="row">
              <div className="col-12 top-bar--hide-responsive">
                <div className="top-bar">
                  <div className="d-flex align-items-center">
                    <div className="form-group inline select-blue">
                      <LangSelect />
                    </div>

                    <div className="top-bar__menu nav">
                      <img
                        className="open-nav burger"
                        src={Menu}
                        alt=""
                        onClick={toggleMenuOpen}
                      />

                      <div
                        className="nav-menu grey"
                        style={{ display: isOpen ? 'block' : 'none' }}
                      >
                        <div className="close">
                          <img src={Close} alt="" onClick={toggleMenuOpen} />
                        </div>
                        <NavContent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="content__elements">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StickyHeader
