import React, {useState} from 'react'
import SEO from '../SEO'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getApiEndpoint } from '../../utils/endpoints';

const Contact = ({ location }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({formulaire: "Contact page"})

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.hasOwnProperty("checked") ? e.target.checked : e.target.value
    })
  }


  return (
    <div className="form-contact">
      <SEO title={t('page_contact_title')} />
      <h2 className="form-contact__title">{t('page_contact_title')}</h2>

      <section className={'visuel_identity'}>
        <div className={'container'}>
          <form
            name={'Contact page'}
            onSubmit={async (e) => {
              e.preventDefault();
              await fetch(`${getApiEndpoint()}/quintyss-contact`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              });
              window.location = location.pathname + (location.pathname.endsWith("/") ? '' : '/') + '?success=true';
              return false;
            }}
          >
            <div className="contact__form no-border  ">
              <div className="form-group input-lg mb-5">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="lastName">
                      {t('constant_label_lastname')}
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="nom"
                      required={true}
                      className="form-control mb-3"
                      onChange={updateData}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="firstName">
                      {t('constant_label_firstname')}
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="prenom"
                      required={true}
                      onChange={updateData}
                      className="form-control mb-3"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="email">{t('constant_label_email')}</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required={true}
                      onChange={updateData}
                      className="form-control mb-3"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="phone">{t('constant_label_tel')}</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      required={true}
                      onChange={updateData}
                      className="form-control mb-3"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-5 input-lg">
                <label htmlFor="lien_du_site">
                  {t('page_contact_form_label_1_1')}
                </label>
                <input
                  type="text"
                  name="lien_du_site"
                  onChange={updateData}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-5  ">
                <label htmlFor="description">
                  {t('page_contact_form_label_2_1')}
                </label>
                <textarea
                  id="link"
                  name="description"
                  required={true}
                  className="form-control"
                  onChange={updateData}
                  rows="4"
                />
              </div>
              <div className="form-group  mb-5">
                <h4 className="functionnality__title section-title">
                  {t('page_contact_form_label_3')}
                </h4>
                <span className="mb-3">{t('page_contact_form_label_3_1')}</span>
                <div className="mt-3">
                  <label className="flex">
                    <input
                        type="checkbox"
                      name={t('page_contact_form_label_3_checkbox_1')}
                      onChange={updateData}
                      id="page_contact_form_label_3_checkbox_1"
                      value={t('page_contact_form_label_3_checkbox_1')}
                    />
                    <span>{t('page_contact_form_label_3_checkbox_1')}</span>
                  </label>
                  <label className="flex">
                    <input
                        type="checkbox"
                      name={t('page_contact_form_label_3_checkbox_2')}
                      id="page_contact_form_label_3_checkbox_2"
                      onChange={updateData}
                      value={t('page_contact_form_label_3_checkbox_2')}
                    />
                    <span>{t('page_contact_form_label_3_checkbox_2')}</span>
                  </label>
                  <label className="flex">
                    <input
                        type="checkbox"
                      name={t('page_contact_form_label_3_checkbox_3')}
                      id="page_contact_form_label_3_checkbox_3"
                      onChange={updateData}
                      value={t('page_contact_form_label_3_checkbox_3')}
                    />
                    <span>{t('page_contact_form_label_3_checkbox_3')}</span>
                  </label>
                  <label className="flex">
                    <input
                      type="checkbox"
                      name={t('page_contact_form_label_3_checkbox_4')}
                      id="page_contact_form_label_3_checkbox_4"
                      onChange={updateData}
                      value={t('page_contact_form_label_3_checkbox_4')}
                    />
                    <span>{t('page_contact_form_label_3_checkbox_4')}</span>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: '10px' }}
              >
                {t('constant_send')}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}

export default Contact
