import React, { useState } from 'react'
import Layout from '../components/Layout'
import StickHeader from '../components/Header/StickyHeader'
import Contact from '../components/Contact'
import Aside from '../components/Contact/Aside'
import { graphql } from 'gatsby'

import Success from '../components/Form/Success'

export default function Home({ pageContext, location }) {
  const [fomValueReason, setFomValueReason] = useState('problem')
  return (
    <Layout
      className="interne one-screen contact"
      locale={pageContext.language}
      template="contact"
    >
      <main>
        <StickHeader
          asideContent={<Aside setFomValueReason={setFomValueReason} />}
        >
          {location.search === '' && <Contact location={location} />}
          {/* success form netlify */}
          {location.search === '?success=true' && <Success />}
        </StickHeader>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
